import { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  BeneficiaryForm,
  ClientPreferencesLevel1,
  ClientPreferencesLevel2,
  Deal,
  FavouriteOffers,
  Offers,
} from "./steps";
import { TaskSidebarButton } from "./TaskSidebarButton";
import { RegionRecommendation } from "./steps/RegionRecommendation/RegionRecommendation";
import { DistrictRecommendations } from "./steps/DistrictRecommendations/DistrictRecommendations";
import { StatusChip } from "@/components/StatusChip";
import { GET_SIGNLE_TASK } from "@/shared/graphql";
import { useQuery } from "@apollo/client";
import { Loading } from "@/components/Loading";
import { RootState } from "@/shared/store";
import { useSelector } from "react-redux";
import { isNegotiator } from "@/shared/utils/mappers";

const steps = [
  {
    label: "Beneficiary data",
    value: "Beneficiary data",
    step: 1,
  },
  {
    label: "Region recommendation",
    value: "Region recommendation",
    step: 3,
  },
  {
    label: "client preferences level2",
    value: "client preferences level2",
    step: 4,
  },
  {
    label: "District recommendation",
    value: "District recommendation",
    step: 5,
  },
  {
    label: "Offers",
    value: "Offers",
    step: 6,
  },
  {
    label: "Favourite offers",
    value: "Favourite offers",
    step: 7,
  },
  {
    label: "Deal",
    value: "Deal",
    step: 8,
  },
];

export const Task = () => {
  const { taskId } = useParams();
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const {
    data: taskRes,
    loading,
    refetch: refetchTask,
  } = useQuery(GET_SIGNLE_TASK, {
    variables: { pk: taskId, role: userInfo?.role?.queryVar },
  });

  const canSubmit = userInfo?.isSuperuser || isNegotiator(userInfo);
  const { t } = useTranslation();
  const [selectedStep, setSelectedStep] = useState(1);

  const handleSelectTab = (value) => {
    setSelectedStep(value);
  };

  if (loading) return <Loading />;
  const task = taskRes?.tasks?.data?.at(0);

  return (
    <div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
        pb={1}
        sx={{ borderBottom: "1px solid #e3e3e3" }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {t("task")} {taskId}#
        </Typography>
        <Box>
          <Box mb={1} textAlign="right">
            <StatusChip status={task?.status} canSubmit={canSubmit} />
          </Box>
        </Box>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={2.5}>
          <Box sx={{ position: "sticky", top: 100 }}>
            {steps?.map((step) => (
              <TaskSidebarButton
                active={selectedStep === step.step}
                onClick={() => handleSelectTab(step.step)}
              >
                {t(step.label)}
              </TaskSidebarButton>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={9.5}>
          {selectedStep === 1 && (
            <BeneficiaryForm
              canSubmit={canSubmit}
              refetchTask={refetchTask}
              setSelectedStep={setSelectedStep}
            />
          )}
          {selectedStep === 2 && (
            <ClientPreferencesLevel1
              canSubmit={canSubmit}
              refetchTask={refetchTask}
              setSelectedStep={setSelectedStep}
            />
          )}
          {selectedStep === 3 && <RegionRecommendation />}
          {selectedStep === 4 && (
            <ClientPreferencesLevel2
              canSubmit={canSubmit}
              refetchTask={refetchTask}
              setSelectedStep={setSelectedStep}
            />
          )}
          {selectedStep === 5 && <DistrictRecommendations />}

          {selectedStep === 6 && <Offers />}
          {selectedStep === 7 && (
            <FavouriteOffers
              refetchTask={refetchTask}
              setSelectedStep={setSelectedStep}
            />
          )}
          {selectedStep === 8 && <Deal canSubmit={canSubmit} />}
        </Grid>
      </Grid>
    </div>
  );
};
