import { Outlet, useRoutes } from "react-router";
import { Navigate } from "react-router-dom";
import { Layout } from "../components/Layout";
import { LandingPage } from "@/modules/LandingPage";
import { useAuth } from "react-oidc-context";
import {
  Beneficiaries,
  CustomersService,
  Negotiators,
  OrderTasks,
  Orders,
} from "@/modules/app/project-manager";
import {
  Tasks as CSTasks,
  Task as CSTask,
} from "@/modules/app/customer-service";
import { FormsPlayground } from "@/modules/app/FormsPlayground";
import { useSelector } from "react-redux";
import { RootState } from "./store";
import {
  Tasks as NegotiatorTasks,
  Task as NegotiatorTask,
} from "@/modules/app/negotiator";
import { UnAuthorized } from "./UnAuthorized";
import { NotFound } from "./NotFound";
import {
  isCustomerService,
  isNegotiator,
  isProjectManager,
  rolesMap,
} from "./utils/mappers";
import { PrivacyPolicy } from "@/modules/PrivacyPolicy";
import { CustomerServiceBeneficiaries } from "@/modules/app/project-manager/Beneficiaries/CustomerServiceBeneficiaries";
import { NegotiatorBeneficiaries } from "@/modules/app/project-manager/Beneficiaries/NegotiatorBeneficiaries";
import { UnassignedBeneficiaries } from "@/modules/app/project-manager/Beneficiaries/UnassignedBeneficiaries";
import { PendingReviewBeneficiaries } from "@/modules/app/project-manager/Beneficiaries/PendingReviewBeneficiaries";

export const Routing = () => {
  const { isAuthenticated } = useAuth();
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const isPM = isProjectManager(userInfo);
  const isCS = isCustomerService(userInfo);
  const isNegot = isNegotiator(userInfo);

  const firstURL = rolesMap[userInfo?.role?.key || ""]?.initialUrl;

  const routes = useRoutes([
    {
      path: "/",
      element: <LandingPage />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "app",
      element: isAuthenticated ? <Layout /> : <Navigate to="/" />,
      children: [
        {
          path: "",
          element: <Navigate to={firstURL} replace />,
        },
        { path: "forms", element: <FormsPlayground /> },
        {
          path: "project-manager",
          element: isPM ? <Outlet /> : <UnAuthorized role={userInfo?.role} />,
          children: [
            {
              path: "dashboard",
              element: <div>Dashboard</div>,
            },
            {
              path: "beneficiaries",
              element: <Beneficiaries />,
              children: [
                { path: "", element: <Navigate to="unassigned" replace /> },
                { path: "unassigned", element: <UnassignedBeneficiaries /> },
                {
                  path: "customer-service",
                  element: <CustomerServiceBeneficiaries />,
                },
                { path: "negotiator", element: <NegotiatorBeneficiaries /> },
                {
                  path: "pending-review",
                  element: <PendingReviewBeneficiaries />,
                },
              ],
            },
            { path: "orders", element: <Orders /> },
            {
              path: "orders/:orderId",
              element: <OrderTasks />,
            },
            {
              path: "orders/:orderId/customer-task/:taskId",
              element: <CSTask />,
            },
            {
              path: "orders/:orderId/negotiator-task/:taskId",
              element: <NegotiatorTask />,
            },
            {
              path: "customer-service",
              element: <CustomersService />,
            },
            {
              path: "negotiators",
              element: <Negotiators />,
            },
          ],
        },
        {
          path: "customer-service",
          element: isCS ? <Outlet /> : <UnAuthorized role={userInfo?.role} />,
          children: [
            {
              path: "tasks",
              element: <CSTasks />,
            },
            {
              path: "tasks/:taskId",
              element: <CSTask />,
            },
          ],
        },
        {
          path: "negotiator",
          element: isNegot ? (
            <Outlet />
          ) : (
            <UnAuthorized role={userInfo?.role} />
          ),
          children: [
            {
              path: "tasks",
              element: <NegotiatorTasks />,
            },
            {
              path: "tasks/:taskId",
              element: <NegotiatorTask />,
            },
          ],
        },
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
  ]);
  return <>{routes}</>;
};
