import { Loading } from "@/components/Loading";
import {
  DatePickerWidget,
  FileWidget,
  SelectWidget,
  TextWidget,
  YesNoWidget,
} from "@/components/RJSF/widgets";
import { GET_SCHEMA_BY_KEY, GET_SIGNLE_TASK } from "@/shared/graphql";
import { useMutation, useQuery } from "@apollo/client";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { CustomObjectFieldTemplate as ObjectFieldTemplate } from "@/components/RJSF/templates/CustomObjectFieldTemplate";
import { Button } from "@mui/material";
import { UPDATE_NEGOTIATOR_TASK } from "@/shared/graphql/mutations";
import toast from "react-hot-toast";
import { fetchAllDistricts } from "@/shared/graphql/services/layers";
import { graphqlErrorHandler } from "@/shared/utils";
import { RootState } from "@/shared/store";
import { useSelector } from "react-redux";
// import schema8 from "@/schemas/3-place-orders.json";
// import uiSchema8 from "@/schemas/ui-schemas/3-place-orders.json";

export const ClientPreferencesLevel2 = ({
  formKey = "place_orders",
  setSelectedStep,
  refetchTask,
  canSubmit,
}) => {
  const { taskId } = useParams();
  const { t } = useTranslation();
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const [formData, setFormData] = useState<any>({
    financialPreferencesLevelTwo: {},
    locationPreferencesLevelTwo: {},
  });
  const [formSchema, setFormSchema] = useState();
  const [uiSchema, setUiSchema] = useState({});

  const { data: taskRes, loading: taskLoading } = useQuery(GET_SIGNLE_TASK, {
    variables: { pk: taskId, role: userInfo?.role?.queryVar },
    onCompleted: (res) => {
      const placeOrderFormData =
        res?.tasks?.data?.at(0)?.formData?.negotiator?.placeOrder;
      if (placeOrderFormData) {
        setFormData((prev) => ({ ...prev, ...placeOrderFormData }));
        return;
      }
      const negotiatorFormData =
        res?.tasks?.data?.at(0)?.formData?.negotiator?.negotiatorData;
      setFormData((prev) => ({
        ...prev,
        locationPreferencesLevelTwo: {
          regionId: negotiatorFormData.locationPreferences.regionId,
          cityId: negotiatorFormData.locationPreferences.cityId,
          zonesId: negotiatorFormData.locationPreferences.zonesId,
          mainDivision: negotiatorFormData.locationPreferences.mainDivision,
          preferredCity: negotiatorFormData.locationPreferences.preferredCity,
          preferredRegion:
            negotiatorFormData.locationPreferences.preferredRegion,
        },
        financialPreferencesLevelTwo: {
          preferredPurchaseAmount:
            negotiatorFormData.financialPreferences.preferredPurchaseAmount,
        },
        realEstatePreferences: {
          preferredPurchaseAmount:
            negotiatorFormData?.realEstatePreferences?.preferredPropertyType,
        },
      }));
    },
  });

  const { loading: schemaLoading } = useQuery(GET_SCHEMA_BY_KEY, {
    variables: { key: formKey },
    onCompleted: async (res) => {
      const schema = JSON.parse(
        JSON.stringify(res.schemas.data?.at(0)?.jsonSchema)
      );
      const fetchedFormSchema = schema.form;
      const fetchedUiSchema = schema.UISchema;
      const requestedFormData = JSON.parse(
        JSON.stringify(
          taskRes?.tasks?.data?.at(0)?.formData?.negotiator?.drafts?.drafts ||
            taskRes?.tasks?.data?.at(0)?.formData?.negotiator?.negotiatorData
        )
      );

      const cityId = requestedFormData?.locationPreferences?.cityId;
      const zonesId = requestedFormData?.locationPreferences?.mainDivision
        ?.map((zone) => {
          if (zone.id) {
            return zone.id;
          }
        })
        .filter((id) => !!id);
      const districtsRes = await fetchAllDistricts(cityId, zonesId);
      const allDistricts = districtsRes?.data?.districts?.data?.map(
        (district) => ({
          id: district?.sourceProperties?.id,
          label: district?.sourceProperties?.district_name,
        })
      );

      if (
        fetchedFormSchema?.properties?.locationPreferencesLevelTwo?.properties
          ?.district
      ) {
        fetchedFormSchema.properties.locationPreferencesLevelTwo.properties.district.items.enum =
          allDistricts;
        fetchedFormSchema.properties.locationPreferencesLevelTwo.properties.district.items.enumNames =
          allDistricts.map((district) => district.label);
      }
      setUiSchema(fetchedUiSchema);
      setFormSchema(fetchedFormSchema);
    },
  });

  const [updateTask, { loading: mutationLoading }] = useMutation(
    UPDATE_NEGOTIATOR_TASK
  );

  const onFormChange = async (form: any) => {
    const clonedFormData = JSON.parse(JSON.stringify(form.formData));

    setFormData((prev) => ({ ...prev, ...clonedFormData }));
  };

  const onFileChange = async (data) => {
    return new Promise((resolve) => {
      if (Array.isArray(data)) {
        const result: any = [];
        data.forEach(({ file }) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            result.push(reader.result);
            if (data.length === result.length) {
              resolve(result);
            }
          };
        });
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(data.file);
        reader.onload = () => {
          resolve(reader.result);
        };
      }
    });
  };

  const onSubmit = (form) => {
    const submittedData = { placeOrder: form.formData };
    updateTask({
      variables: {
        taskInput: {
          taskId,
          formSchemaKey: formKey,
          formData: JSON.stringify(submittedData),
          isDraft: false,
        },
      },
    })
      .then(() => {
        refetchTask();
        toast.success(t("Data has been saved successfully"));
        setSelectedStep((prev) => prev + 1);
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };

  if (schemaLoading || taskLoading) return <Loading />;

  return (
    <>
      <Form
        formContext={{ onFileChange }}
        schema={formSchema || {}}
        uiSchema={uiSchema || {}}
        validator={validator}
        onChange={onFormChange}
        formData={formData}
        noHtml5Validate
        showErrorList={false}
        transformErrors={(errors) => {
          const modfiedErrors = errors?.map((err) => {
            if (
              err.name === "required" ||
              err.name === "minItems" ||
              err.name === "type"
            ) {
              return { ...err, message: "حقل مطلوب" };
            }
            if (err.name === "enum") {
              return {
                ...err,
                message: "يرجى الإختيار من القيم الموجودة",
              };
            }
            if (err.name === "if") {
              return {
                ...err,
                message: "",
              };
            }
            return err;
          });
          return modfiedErrors;
        }}
        widgets={{
          FileWidget,
          YesNoWidget,
          SelectWidget,
          TextWidget,
          DateWidget: DatePickerWidget,
        }}
        templates={{ ObjectFieldTemplate }}
        onSubmit={onSubmit}
      >
        {/* <Button
          onClick={saveAsDraft}
          variant="contained"
          sx={{ mt: 2, mr: 1 }}
          disabled={mutationLoading}
        >
          {t("save and complete later")}
        </Button> */}
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 2 }}
          disabled={!canSubmit || mutationLoading}
        >
          {t("save")}
        </Button>
      </Form>
    </>
  );
};
